<template>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="146px" height="72px" viewBox="0 0 1460 720" preserveAspectRatio="xMidYMid meet">
<g id="layer101" fill="#1b3a67" stroke="none">
 <path d="M0 360 l0 -360 730 0 730 0 0 360 0 360 -730 0 -730 0 0 -360z"/>
 </g>
<g id="layer102" fill="#0867a9" stroke="none">
 <path d="M0 360 l0 -360 730 0 730 0 0 360 0 360 -730 0 -730 0 0 -360z m674 120 c-43 -124 -63 -170 -74 -170 -23 0 -99 51 -140 94 -29 31 -32 39 -20 46 8 5 76 53 150 108 74 55 137 98 139 96 2 -2 -23 -80 -55 -174z"/>
 </g>
<g id="layer103" fill="#e42a5e" stroke="none">
 <path d="M0 360 l0 -360 730 0 730 0 0 360 0 360 -730 0 -730 0 0 -360z m798 95 l59 -190 -25 -13 c-23 -13 -27 -11 -76 35 -43 41 -91 73 -112 73 -2 0 -9 -11 -14 -25 -5 -14 -17 -25 -28 -25 -25 0 -100 50 -142 94 -29 31 -32 39 -20 46 8 5 76 53 150 108 74 55 138 97 142 93 3 -3 33 -91 66 -196z"/>
 </g>
<g id="layer104" fill="#189ad5" stroke="none">
 <path d="M0 360 l0 -360 730 0 730 0 0 360 0 360 -730 0 -730 0 0 -360z m796 100 l61 -195 -25 -13 c-23 -13 -27 -11 -76 35 -43 41 -91 73 -112 73 -2 0 -9 -11 -14 -25 -5 -14 -17 -25 -28 -25 -25 0 -99 49 -141 93 l-34 36 -63 -44 c-68 -49 -67 -50 -104 24 -28 56 -47 120 -56 188 l-6 53 268 -2 269 -3 61 -195z"/>
 </g>
<g id="layer105" fill="#24b4e3" stroke="none">
 <path d="M0 360 l0 -360 730 0 730 0 0 360 0 360 -730 0 -730 0 0 -360z m965 129 l237 -172 -49 -49 c-26 -27 -70 -63 -96 -80 l-48 -31 -76 74 c-61 61 -74 70 -71 50 4 -32 -19 -39 -127 -40 -107 -1 -125 5 -125 38 0 22 -7 29 -35 40 -20 7 -61 37 -93 66 l-57 53 -61 -44 c-68 -48 -67 -49 -104 25 -28 56 -47 120 -56 187 l-6 54 265 0 266 0 236 -171z"/>
 </g>
<g id="layer106" fill="#a8daf0" stroke="none">
 <path d="M0 360 l0 -360 730 0 730 0 0 360 0 360 -730 0 -730 0 0 -360z m854 213 c67 -48 172 -125 234 -171 l113 -83 -32 -40 c-37 -47 -137 -120 -203 -149 -26 -11 -49 -20 -52 -20 -2 0 -13 31 -25 70 -11 39 -27 70 -34 70 -7 0 -37 -5 -66 -10 -38 -8 -74 -7 -121 0 -66 11 -67 12 -62 39 5 23 1 28 -31 39 -19 8 -61 38 -93 67 l-57 53 -61 -44 c-68 -48 -67 -49 -104 25 -28 56 -47 120 -56 187 l-6 54 267 0 268 0 121 -87z"/>
 </g>
<g id="layer107" fill="#f6b9c8" stroke="none">
 <path d="M0 360 l0 -360 730 0 730 0 0 360 0 360 -730 0 -730 0 0 -360z m1210 267 c0 -47 -27 -133 -60 -193 l-29 -52 39 -27 c22 -15 40 -33 40 -40 0 -7 -31 -42 -69 -78 -67 -63 -186 -134 -212 -125 -7 2 -23 33 -35 69 -14 42 -26 63 -35 61 -37 -10 -169 -13 -204 -5 -34 8 -40 13 -40 36 -1 22 -10 32 -54 54 -29 15 -70 46 -90 69 l-38 41 -59 -43 c-33 -23 -62 -40 -67 -37 -12 7 -58 102 -72 151 -8 25 -17 69 -21 98 l-6 54 506 0 506 0 0 -33z"/>
 </g>
<g id="layer108" fill="#fefefe" stroke="none">
 <path d="M0 360 l0 -360 730 0 730 0 0 360 0 360 -730 0 -730 0 0 -360z m848 303 l362 -6 0 -32 c0 -46 -27 -132 -60 -191 l-29 -52 39 -27 c22 -15 40 -33 40 -40 0 -7 -31 -42 -69 -78 -67 -63 -186 -134 -212 -125 -7 2 -23 33 -35 69 -14 42 -26 63 -35 61 -37 -10 -169 -13 -204 -5 -34 8 -40 13 -40 36 -1 21 -10 31 -50 52 -28 15 -69 45 -91 67 l-42 40 -54 -41 c-30 -23 -59 -41 -65 -41 -14 0 -68 104 -87 169 -9 30 -16 76 -16 103 l0 48 143 0 c79 0 306 -3 505 -7z"/>
 </g>

</svg>
</template>

<script>
export default {
  name: 'ProjectLogo'
}
</script>
